export class Enum {

    static get TABLE_FORMAT() {
        return {
            String: 'string',
            FileSize: 'filesize',
            Date: 'timestamp',
            Integer: 'int',
            Float: 'float',
            Currency: 'currency',
        }
    }

}
