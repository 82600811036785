<template>
    <div class="mb-1">
        <label :for="inputId" class="form-label">{{title ? title : label}}</label>
        <div class="row">
            <div class="col-2">
                <div class="input-group">
                    <span v-if="prefix" class="input-group-text" id="basic-addon1">{{prefix}}</span>
                    <input
                        :disabled="disabled"
                        :readonly="readonly"
                        type="number"
                        class="form-control bg-dark"
                        v-model="baseValue"
                        @change="$emit('valueChange', label, baseValue)"
                    />
                    <span v-if="suffix" class="input-group-text" id="basic-addon2">{{suffix}}</span>
                </div>
            </div>
            <div class="col-10">
                <input
                    :disabled="disabled"
                    :readonly="readonly"
                    type="range"
                    class="form-range bg-dark"
                    v-model="baseValue"
                    :id="inputId"
                    :max="max"
                    :min="min"
                    :step="step"
                    :aria-describedby="`${inputId}Help`"
                    @change="$emit('valueChange', label, baseValue)"
                />
            </div>
        </div>
        <div v-if="help !== ''" :id="`${inputId}Help`" class="form-text" v-html="help"></div>
    </div>
</template>

<script>
import Big from "big.js";

export default {
    name: "BootstrapRange",
    props: {
        // id: {
        //     type: String,
        //     required: false,
        //     default: '',
        // },
        // name: {
        //     type: String,
        //     required: true,
        //     default: '',
        // },
        label: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        help: {
            type: String,
            required: false,
            default: '',
        },
        defaultValue: {
            type: [String, Number, Big],
            required: false,
            default: '',
        },
        override: {
            type: [String, Number, Big],
            required: false,
            default: null,
        },
        min: {
            type: Number,
            required: false,
            default: 0,
        },
        max: {
            type: Number,
            required: false,
            default: 100,
        },
        step: {
            type: Number,
            required: false,
            default: 1,
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        if (this.override !== null) {
            this.baseValue = this.override;
        } else {
            let value = this.$attrs.modelValue;
            if (value === undefined) {
                value = '';
            }
            this.inputId = this.generateString(8);
            if (value !== '') {
                this.baseValue = value;
            } else if (value === '' && this.defaultValue !== '') {
                this.baseValue = this.defaultValue;
            }
        }
    },
    watch: {
        async defaultValue(newValue) {
            if (this.override === null) {
                this.baseValue = newValue;
            }
        }
    },
    computed: {

    },
    methods: {
        generateString(length) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        inputId: '',
        baseValue: '',
    })
}
</script>

<style scoped>

</style>
