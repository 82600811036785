import axios from 'axios';
import Functions from "@/libs/Functions";

class AJAX {

    async get(url, version) {
        if (version === undefined || version === null) {
            version = 1;
        }

        return await new Promise((resolve, reject) => {
            axios.get(`${Functions.getOriginPath()}/api/v${version}/${url}`, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        reject(401);
                    }
                    if (error.message === 'Network Error') {
                        reject(error.message);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async post(url, data) {
        return await new Promise((resolve, reject) => {
            axios.post(`${Functions.getOriginPath()}/api/v1/${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.error(error);
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async put(url, data) {
        return await new Promise((resolve, reject) => {
            axios.put(`${Functions.getOriginPath()}/api/v1/${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async delete(url, data) {
        return await new Promise((resolve, reject) => {
            let configs = this.getConfigs();
            configs.data = data;
            axios.delete(`${Functions.getOriginPath()}/api/v1/${url}`, configs)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async rawGet(url) {
        return await new Promise((resolve, reject) => {
            axios.get(`${url}`, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async rawPost(url, data) {
        return await new Promise((resolve, reject) => {
            axios.post(`${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }


    getConfigs() {
        return {
            headers: {
                service: 'XNSRelayer',
                request: new Date(),
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }
        };
    }

}

export default new AJAX();
