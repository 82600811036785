<template>
    <div :class="`form-check${this.switch ? ' form-switch' : ''}`">
        <div class="input-group">
            <span v-if="prefix" class="input-group-text" id="basic-addon1">{{prefix}}</span>
            <input
                class="form-check-input"
                type="checkbox"
                :id="inputId"
                :aria-describedby="`${inputId}Help`"
                :disabled="disabled"
                v-model="baseValue"
                :checked="baseValue"
                @change="$emit('valueChange', label, baseValue)"
            />
            <label class="form-check-label ms-2" :for="inputId" v-if="showTitle">{{title ? title : label}}</label>
            <span v-if="suffix" class="small ms-2 mt-1" id="basic-addon2">{{suffix}}</span>
        </div>

        <div v-if="help !== ''" :id="`${inputId}Help`" class="form-text">{{help}}</div>
    </div>
</template>

<script>
export default {
    name: "BootstrapCheckbox",
    props: {
        label: {
            type: [String, Number],
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: true,
        },
        switch: {
            type: Boolean,
            required: false,
            default: true,
        },
        help: {
            type: String,
            required: false,
            default: '',
        },
        defaultValue: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
    },
    created() {
        let value = this.$attrs.modelValue;
        this.inputId = this.generateString(8);
        if (value !== '') {
            this.baseValue = value;
        } else if (value === '' && this.defaultValue !== '') {
            this.baseValue = this.defaultValue;
        }
    },
    watch: {
        async defaultValue(newValue) {
            this.baseValue = newValue;
        }
    },
    methods: {
        generateString(length) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        inputId: '',
        baseValue: false,
    })
}
</script>
<style lang="scss">
//@import "bootstrap/scss/_variables.scss";
//$form-check-min-height: $font-size-base * $line-height-base + 5;
</style>
