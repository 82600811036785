<template>
    <div class="mb-1">
        <label :for="inputId" class="form-label">{{title ? title : label}}</label>
        <div class="input-group">
            <span v-if="prefix" class="input-group-text" id="basic-addon1">{{prefix}}</span>
            <span v-if="prefixIcon" class="input-group-text" id="basic-addon1"><font-awesome-icon :icon="prefixIcon" /></span>
            <textarea
                :rows="rows"
                :disabled="disabled"
                :readonly="readonly"
                class="form-control"
                :id="inputId"
                :aria-describedby="`${inputId}Help`"
                @change="$emit('valueChange', label, baseData)"
                v-model="baseData"
            />
            <span v-if="suffix" class="input-group-text" id="basic-addon2">{{suffix}}</span>
            <span v-if="suffixIcon" class="input-group-text" id="basic-addon2"><font-awesome-icon :icon="suffixIcon" /></span>
        </div>
        <div v-if="help !== ''" :id="`${inputId}Help`" class="form-text">{{help}}</div>
    </div>
</template>

<script>
import Big from "big.js";

export default {
    name: "BootstrapTextArea",
    props: {
        // id: {
        //     type: String,
        //     required: false,
        //     default: '',
        // },
        // name: {
        //     type: String,
        //     required: true,
        //     default: '',
        // },
        label: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        rows: {
            type: Number,
            required: false,
            default: 1,
        },
        help: {
            type: String,
            required: false,
            default: '',
        },
        defaultValue: {
            type: [String, Number, Big],
            required: false,
            default: '',
        },
        override: {
            type: [String, Number, Big],
            required: false,
            default: null,
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        prefixIcon: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        suffixIcon: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        if (this.override !== null) {
            this.baseValue = this.override;
        } else {
            let value = this.$attrs.modelValue;
            if (value === undefined) {
                value = '';
            }
            this.inputId = this.generateString(8);
            if (value !== '') {
                this.baseValue = value;
            } else if (value === '' && this.defaultValue !== '') {
                this.baseValue = this.defaultValue;
            }
        }
    },
    watch: {
        async defaultValue(newValue) {
            if (this.override === null) {
                this.baseData = newValue;
            }
        }
    },
    computed: {

    },
    methods: {
        generateString(length) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        refreshContent(baseData) {
            this.baseData = baseData;
        },
        fetchContent() {
            return this.baseData;
        }
    },
    data: () => ({
        inputId: '',
        baseData: '',
    })
}
</script>

<style scoped>

</style>
