<template>
    <div class="mb-1">
        <div class="input-group">
            <span v-if="prefix" class="input-group-text" id="basic-addon1">{{prefix}}</span>
            <span v-if="prefixIcon" class="input-group-text" id="basic-addon1"><font-awesome-icon :icon="prefixIcon" /></span>
            <template v-if="hideTitle">
                <input
                    :disabled="disabled"
                    :readonly="readonly"
                    :type="type"
                    :placeholder="placeholder"
                    class="form-control"
                    v-model="baseValue"
                    :id="inputId"
                    :aria-describedby="`${inputId}Help`"
                    @change="onChange"
                    @keyup="processLiveEvent"
                    :maxlength="(max > 0 ? max : null)"
                    :ref="inputId"
                    @input="limitLength"
                />
            </template>
            <template v-else>
                <div class="form-floating">
                    <input
                        :disabled="disabled"
                        :readonly="readonly"
                        :type="type"
                        :placeholder="placeholder"
                        class="form-control"
                        v-model="baseValue"
                        :id="inputId"
                        :aria-describedby="`${inputId}Help`"
                        @change="onChange"
                        @keyup="processLiveEvent"
                        :maxlength="(max > 0 ? max : null)"
                        :ref="inputId"
                        @input="limitLength"
                    />
                    <label v-if="!hideTitle" :for="inputId">{{title ? title : label}}</label>
                </div>
            </template>
            <span v-if="suffix" class="input-group-text" id="basic-addon2">{{suffix}}</span>
            <span v-if="suffixIcon" class="input-group-text" id="basic-addon2"><font-awesome-icon :icon="suffixIcon" /></span>
        </div>
        <div v-if="max !== 0 && !hideMax" :id="`${inputId}Length`" class="form-text float-end" v-html="`Max Length: ${defaultValue.length}/${max} Characters`" />
        <div v-if="help !== ''" :id="`${inputId}Help`" class="form-text" v-html="help" />
    </div>
</template>

<script>
export default {
    name: "BootstrapInput",
    props: {
        label: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        validate: {
            type: Function,
            required: false,
            default: null,
        },
        liveUpdate: {
            type: Function,
            required: false,
            default: null,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        help: {
            type: String,
            required: false,
            default: '',
        },
        defaultValue: {
            type: [String, Number],
            required: false,
            default: '',
        },
        override: {
            type: [String, Number],
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        unitConverter: {
            type: Object,
            required: false,
            default: null,
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        prefixIcon: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        suffixIcon: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        max: {
            type: Number,
            required: false,
            default: 0
        },
        hideMax: {
            type: Boolean,
            required: false,
            default: false,
        },
        live: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        if (this.override !== null) {
            this.baseValue = this.override;
        } else {
            let value = this.$attrs.modelValue;
            if (value === undefined) {
                value = '';
            }
            this.inputId = this.generateString(8);
            if (value !== '') {
                this.baseValue = value;
            } else if (value === '' && this.defaultValue !== '') {
                this.baseValue = this.defaultValue;
            }
            if (this.baseValue === undefined) {
                this.baseValue = '';
            }
        }
    },
    watch: {
        async defaultValue(newValue) {
            if (this.override === null) {
                this.baseValue = newValue;
            }
        }
    },
    methods: {
        limitLength() {
            if (this.max > 0 && this.type === 'number') {
                if (this.baseValue.toString().length > this.max) {
                    this.baseValue = this.baseValue.toString().slice(0, this.max);
                }
            }
        },
        onChange() {
            if (this.validate !== null) {
                this.isValid = this.validate(this.label, this.baseValue);
                if (this.isValid) {
                    this.$emit('valueChange', this.label, this.baseValue);
                }
            } else {
                this.$emit('valueChange', this.label, this.baseValue);
            }
        },
        processLiveEvent() {
            if (this.live === false) {
                return;
            }
            this.$emit('onChanging', this.label, this.$refs[this.inputId].value);
        },
        generateString(length) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        isValid: true,
        inputId: '',
        baseValue: '',
    })
}
</script>
