<template>
    <div>
        <template v-if="searchable">
            <BootstrapInput
                label="searchTable"
                title="Search..."
                suffix-icon="search"
                placeholder="Search any record within the table."
                @onChanging="updateTableSearch"
                live
            />
        </template>
        <table class="table">
            <thead class="table-dark">
                <tr>
                    <th
                        scope="col"
                        :class="`${noSortColumn.includes(label) || noSort ? '' : 'clickHand'} h5 ${label === 'Actions' ? 'text-end' : ''}`"
                        v-for="(label, pos) of headers"
                        :key="pos"
                        @click="sortTable(pos, label)"
                    >
                        {{label}}
                        <span class="float-end" v-if="!noSort && noSortColumn.includes(label) === false">
                            <font-awesome-icon :icon="`sort-${sorting.asc ? 'up' : 'down'}`" v-if="sorting.column === pos" />
                            <font-awesome-icon icon="sort" v-else />
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="!loading">
                    <tr v-for="(row, id2) of showPageRecords" :key="id2">
                        <td v-for="(contents, id3) of row.data" :key="id3" :class="formatRowClass(row)" v-html="formatRow(contents, id3)"></td>
                        <td v-if="row.actions" class="text-end">
                            <template v-if="splitButton">
<!--                                <div class="btn-group">-->
                                    <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                                        Actions <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li v-for="(action, id4) of row.actions" :key="id4">
                                            <template v-if="action.divider">
                                                <hr class="dropdown-divider">
                                            </template>
                                            <template v-else>
                                                <button :disabled="action.disabled" @click="performAction(action)" :title="action.name" :class="`dropdown-item ${action.class}`">
                                                    <font-awesome-icon v-if="action.icon" :icon="action.icon" /> <span v-if="action.noText !== true">{{action.name}}</span>
                                                </button>
                                            </template>
                                        </li>
<!--                                        <li><a class="dropdown-item" href="#">Action</a></li>-->
<!--                                        <li><a class="dropdown-item" href="#">Another action</a></li>-->
<!--                                        <li><a class="dropdown-item" href="#">Something else here</a></li>-->
<!--                                        <li><hr class="dropdown-divider"></li>-->
<!--                                        <li><a class="dropdown-item" href="#">Separated link</a></li>-->
                                    </ul>
<!--                                </div>-->
                            </template>
                            <template v-else>
                                <template v-if="typeof(row.actions) === 'object'">
                                    <button v-for="(action, id4) of row.actions" :key="id4" :disabled="action.disabled" @click="performAction(action)" :class="`btn ${action.class} me-1 ms-1`" :title="action.name">
                                        <font-awesome-icon v-if="action.icon" :icon="action.icon" /> <span v-if="action.noText !== true">{{action.name}}</span>
                                    </button>
                                </template>
                                <template v-else>
                                    {{row.actions}}
                                </template>
                            </template>
                        </td>
                    </tr>
                    <tr v-if="showPageRecords.length === 0">
                        <td :colspan="headers.length" class="text-center">- No Records -</td>
                    </tr>
                    <tr v-if="message">
                        <td :colspan="headers.length" class="text-center">{{message}}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr v-if="showPageRecords.length === 0">
                        <td :colspan="headers.length" class="text-center">- Loading records, please wait... -</td>
                    </tr>
                </template>
            </tbody>
        </table>

        <div class="row">
            <div class="col-8">
                <div class="btn-group" role="group" aria-label="Pagination" v-if="getTotalPages > 1">
                    <button @click="firstPage" class="btn btn-sm btn-secondary" :disabled="currentPage === 1">First</button>
                    <button @click="prevPage" class="btn btn-sm btn-secondary" :disabled="currentPage === 1">Previous</button>

                    <button @click="gotoPage(page)" class="btn btn-sm btn-secondary" v-for="(page, id) of processPreviousPages" :key="`before-${id}`">{{page}}</button>
                    <button @click="gotoPage(currentPage)" class="btn btn-sm btn-primary">{{currentPage}}</button>
                    <button @click="gotoPage(page)" class="btn btn-sm btn-secondary" v-for="(page, id2) of processNextPages" :key="`after-${id2}`">{{page}}</button>

                    <!--                    <button @click="gotoPage(page)" :class="`btn btn-sm ${currentPage === page ? 'btn-primary' : 'btn-secondary'}`" v-for="page of getPageArray" :key="page">{{page}}</button>-->
                    <button @click="nextPage" class="btn btn-sm btn-secondary" :disabled="currentPage === getTotalPages">Next</button>
                    <button @click="lastPage" class="btn btn-sm btn-secondary" :disabled="currentPage === getTotalPages">Last</button>
                </div>
            </div>
            <div class="col-4 text-end">
                Showing {{data.length === 0 ? 0 : start+1}} to {{((parseInt(start) + parseInt(recordsPerPage)) > data.length ? data.length : parseInt(start) + parseInt(recordsPerPage))}} of {{data.length}} records.
            </div>
        </div>
    </div>
</template>

<script>
import {Enum} from "@/system/enums";

export default {
    name: "BootstrapTable",
    props: {
        headers: {
            type: Array,
            required: true,
        },
        headerConfig: {
            type: Array,
            required: false,
            default: () => ([]),
        },
        recordsPerPage: {
            type: Number,
            required: false,
            default: 10,
        },
        data: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            required: false,
            default: null,
        },
        noSortColumn: {
            type: Array,
            required: false,
            default: () => (['Actions']),
        },
        noSort: {
            type: Boolean,
            required: false,
            default: false,
        },
        defaultSort: {
            type: Number,
            required: false,
            default: -1,
        },
        defaultSortIsAsc: {
            type: Boolean,
            required: false,
            default: true,
        },
        searchable: {
            type: Boolean,
            required: false,
            default: false,
        },
        splitButton: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        if (this.noSort) {
            this.sorting.column = -1;
        } else {
            this.sorting.column = this.defaultSort;
            this.sorting.asc = this.defaultSortIsAsc;
        }
    },
    watch: {
        async data(a,b) {
            if (a.length !== b.length) {
                this.currentPage = 1;
                this.currentData = this.data;
            }
        }
    },
    computed: {
        formatRows() {
            let result = [];
            for (let row of this.headerConfig) {
                if (row === null) {
                    result.push(Enum.TABLE_FORMAT.String);
                } else {
                    result.push(row);
                }
            }
            return result;
        },
        processPreviousPages() {
            if (this.currentPage === 1) {
                return [];
            }
            let pageArray = [];
            // console.log("For Loop Start;");
            for (let i = (this.currentPage - 1); i > 0; i--) {
                pageArray.push(i);
                // console.log("Adding", i, "Array Length", pageArray.length, "Next Page Length", this.processNextPages.length);
                // if (pageArray.length >= 3 + (3 - (this.getTotalPages - this.currentPage))) {
                if (pageArray.length >= 3) {
                    break;
                }
            }
            // console.log("For Loop Exit;");
            pageArray = pageArray.reverse();
            return pageArray;
        },
        processNextPages() {
            if (this.currentPage === this.getTotalPages) {
                return [];
            }
            let pageArray = [];
            for (let i = (this.currentPage + 1); i < this.getTotalPages; i++) {
                pageArray.push(i);
                if (pageArray.length >= 3 + (3 - this.processPreviousPages.length)) {
                    break;
                }
            }
            return pageArray;
        },
        getTotalPages() {
            return Math.ceil(this.data.length / this.recordsPerPage);
        },
        getPageArray() {
            let pages = [];
            for (let i = 1; i <= this.getTotalPages; i++) {
                pages.push(i);
            }
            return pages;
        },
        showPageRecords() {
            let data = this.currentData.slice();
            // if (this.search) {
            //     this.updateTableSearch('search', this.search);
            // }

            if (this.sorting.column >= 0) {
                let columnType = this.formatRows[this.sorting.column];

                const formatSort = (a, b) => {
                    let aTemp = a.data[this.sorting.column];
                    if (aTemp !== undefined) {
                        let bTemp = b.data[this.sorting.column];
                        if (typeof (aTemp) === 'number') {
                            return (aTemp < bTemp ? -1 : (aTemp > bTemp ? 1 : 0));
                        } else {
                            if (columnType === Enum.TABLE_FORMAT.String) {
                                aTemp = aTemp.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
                                bTemp = bTemp.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
                            }
                            return aTemp.localeCompare(bTemp);
                        }
                    }
                };

                data = data.sort(formatSort);
                if (this.sorting.asc === false) {
                    data = data.reverse();
                }
            }

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.start = 0;
            if (this.currentPage > 1) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.start = this.recordsPerPage * this.currentPage - this.recordsPerPage;
            }
            let rows = [];
            for (let i = this.start; i < (this.start + this.recordsPerPage); i++) {
                if (data[i] !== undefined) {
                    rows.push(data[i]);
                }
            }
            return rows;
        }
    },
    methods: {
        forceRefresh(newData) {
            this.currentPage = 1;
            this.currentData = newData;
            if (this.search) {
                this.$nextTick(() => {
                    this.updateTableSearch('searchTable', this.search);
                });
            }
        },
        /**
         * @param value
         * @param {Enum.TABLE_FORMAT} format
         */
        formatRow(value, pos) {
            let format = this.formatRows[pos];
            if (value === '' || value === undefined || value === null) {
                return '';
            }
            let date;
            switch (format) {
                case Enum.TABLE_FORMAT.Integer:
                    return parseInt(value);
                case Enum.TABLE_FORMAT.Float:
                    return parseFloat(value, 2).toFixed(2);
                case Enum.TABLE_FORMAT.Currency:
                    if (isNaN(parseFloat(value, 2))) {
                        return value;
                    }
                    return "$"+parseFloat(value, 2).toFixed(2);
                case Enum.TABLE_FORMAT.Date:
                    date = new Date(value).toLocaleString();
                    return (date === 'Invalid Date' ? value : date);
                case Enum.TABLE_FORMAT.FileSize:
                    return this.formatSize(value);
                case Enum.TABLE_FORMAT.String:
                default:
                    return value;
            }
        },
        updateTableSearch(key, value) {
            if (value === '') {
                value = null;
            }
            this.search = value;
            if (this.search === null) {
                this.currentData = this.data;
                this.currentPage = 1;
            } else {
                let newData = [];
                for (let row of this.data) {
                    let hasMyContent = false;
                    for (let col of row.data) {
                        if (typeof(col) === 'string') {
                            if (col.toLowerCase().includes(this.search.toLowerCase())) {
                                hasMyContent = true;
                                break;
                            }
                        }
                    }
                    if (hasMyContent) {
                        newData.push(row);
                    }
                    // console.log(row.data);
                }
                this.currentData = newData;
            }
        },
        firstPage() {
            this.currentPage = 1;
        },
        gotoPage(page) {
            this.currentPage = page;
        },
        lastPage() {
            this.currentPage = this.getTotalPages;
        },
        prevPage() {
            this.currentPage--;
            if (this.currentPage === 0) {
                this.currentPage = 1;
            }
        },
        nextPage() {
            this.currentPage++;
            if (this.currentPage > this.getTotalPages) {
                this.currentPage = this.getTotalPages;
            }
        },
        performAction(row) {
            if (row.confirm) {
                if (confirm(`Are you sure you wish to ${row.action} ${row.reference}?`)) {
                    this.$emit(row.action, row.reference);
                }
            } else {
                this.$emit(row.action, row.reference);
            }
        },
        sortTable(column, label) {
            if (this.noSortColumn.includes(label)) {
                return;
            }
            if (column === this.sorting.column) {
                this.sorting.asc = !this.sorting.asc;
            } else {
                this.sorting = {
                    column: column,
                    asc: true,
                }
            }
        },
        formatRowClass(row) {
            let classMap = [];
            if (row.extras) {
                if (row.extras.linethrough) {
                    classMap.push('text-decoration-line-through text-muted');
                }
            }
            return classMap.join(' ');
        },
    },
    data: () => ({
        start: 0,
        currentPage: 1,
        sorting: {
            column: 0,
            asc: true,
        },
        search: null,
        currentData: [],
    })
}
</script>
<style scoped>
    tbody tr td {
        vertical-align: middle;
    }
</style>
