<template>
    <router-view v-if="systemReady && !unauthorized"></router-view>
    <div id="app" v-else-if="!systemReady && !unauthorized">
        <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="card shadow-lg">
                <div class="card-header text-bg-info">
                    Checking Authentication
                </div>
                <div class="card-body text-center" style="min-width: 320px; width: 480px;">
                    Checking authentication with central servers<br />
                    If this takes more then two minutes please restart your relayer.
                </div>
            </div>
        </div>
    </div>
    <div id="app" v-else-if="!systemReady && unauthorized">
        <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="card shadow-lg">
                <div class="card-header text-bg-info">
                    Unauthorized Login Attempt
                </div>
                <div class="card-body text-center" style="min-width: 320px; width: 480px;">
                    Your current login credentials do not permit access to this relayer.<br/>
                    This event has been logged.<br />
                    <button class="btn btn-dark mt-2" type="button" @click="$keycloak.logoutFn()">
                        Logout
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import axios from "axios";
// import VueKeyCloak from '@dsb-norge/vue-keycloak-js';

export default {
    name: 'XNS-Relayer',
    props: {
        uiState: {
            type: String,
            required: true
        }
    },
    mounted() {
        if (this.cacheInterval === null) {
            this.cacheInterval = setInterval(this.loadCache.bind(this), 30000);
        }
        this.loadCache();
        this.BUS.on('ReloadCache', () => {
            this.loadCache();
        });
    },
    unmounted() {
        if (this.cacheInterval !== null) {
            clearInterval(this.cacheInterval);
            this.cacheInterval = null;
        }
    },
    methods: {
        async loadCache() {
            try {
                this.$store.commit('setCache', await this.AJAX.get('system/cache'));
                if (this.Cache.Authenticated === false) {
                    this.systemReady = false;
                    // if (this.$keycloak === undefined) {
                    //     this.loadKeyCloak();
                    // }
                } else {
                    this.systemReady = true;
                }
            } catch (e) {
                if (e === 401) {
                    this.systemReady = false;
                    this.unauthorized = true;
                }
            }
        },
        // loadKeyCloak() {
        //     window['Vue'].use(VueKeyCloak, {
        //         config: {
        //             url: 'https://auth.xapro.net/auth',
        //             realm: 'scprime',
        //             clientId: 'relayer'
        //         },
        //         init: {
        //             onLoad: 'login-required',
        //             checkLoginIframe: true,
        //         },
        //         onReady: (async keycloak => {
        //             await keycloak.loadUserInfo();
        //             this.tokenInterceptor();
        //             this.loadCache();
        //         }),
        //         onInitError: (error => {
        //             console.log("Keycloak error...", error);
        //         }),
        //     });
        // },
        // tokenInterceptor() {
        //     axios.interceptors.request.use(config => {
        //         if (this.$keycloak.authenticated) {
        //             config.headers.KeycloakToken = this.$keycloak.token;
        //             console.log("Keycloak Token", config.headers.KeycloakToken);
        //             config.headers.Authorization = `Bearer ${this.$keycloak.token}`;
        //             if (this.$keycloak.keycloak.userInfo.id) {
        //                 config.headers.KeycloakID = this.$keycloak.keycloak.userInfo.id;
        //             }
        //         }
        //         return config;
        //     }, error => {
        //         return Promise.reject(error)
        //     })
        // }
    },
    created() {

    },
    components: {
    },
    data: () => ({
        cacheInterval: null,
        systemReady: false,
        unauthorized: false,
    })
}
</script>
<style>
</style>
