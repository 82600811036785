// import store from 'vuex';
import moment from 'moment';

import AJAX from "@/xns/AJAX";
import EventBus from "@/libs/EventBus";

import BootstrapCard from "@/components/bootstrap/BootstrapCard";
import BootstrapCheckbox from "@/components/bootstrap/BootstrapCheckbox";
import BootstrapInput from "@/components/bootstrap/BootstrapInput";
import BootstrapSelect from "@/components/bootstrap/BootstrapSelect";
import BootstrapTabs from "@/components/bootstrap/BootstrapTabs";
import BootstrapTable from "@/components/bootstrap/BootstrapTable";
import BootstrapDataList from "@/components/bootstrap/BootstrapDataList.vue";
import BootstrapTextArea from "@/components/bootstrap/BootstrapTextArea.vue";
import BootstrapRange from "@/components/bootstrap/BootstrapRange.vue";
import RelayerModel from "@/components/xns/RelayerModal.vue";
import byteSize from "@/libs/ByteSize";
import Functions from "@/libs/Functions";

export default {
    mounted() {
        document.title = 'XNS Relayer'
    },
    data() {
        return { /* data */ }
    },
    components: {
        BootstrapCard,
        BootstrapCheckbox,
        BootstrapInput,
        BootstrapSelect,
        BootstrapTabs,
        BootstrapTable,
        BootstrapDataList,
        BootstrapTextArea,
        BootstrapRange,
        RelayerModel,
    },
    methods: {
        getReadableFileSizeString(fileSizeInBytes) {
            let i = -1;
            let byteUnits = [' kb', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
            do {
                fileSizeInBytes = fileSizeInBytes / 1024;
                i++;
            } while (fileSizeInBytes > 1024);

            return Math.max(fileSizeInBytes, 0.1).toFixed(2) + byteUnits[i];
        },
        _toast() {
            let that = this;
            return {
                show(message, title, subtitle, delay, type, close) {
                    that.BUS.emit('toast', {
                        title: title,
                        subtitle: subtitle,
                        text: message,
                        delay: delay || 2000,
                        type: type || 'success',
                        closeButton: close || true
                    })
                },
                quick(message, type) {
                    this.show(message, null, null, 5000, type, false);
                },
                hide() {
                    that.BUS.emit('toast');
                }
            }
        },
        _pleaseWait() {
            let that = this;
            return {
                show(message, title) {
                    that.BUS.emit('pleaseWait', {message, title});
                },
                hide() {
                    that.BUS.emit('pleaseWait', null);
                }
            }
        },
        byteSize(size) {
            return byteSize(size, { units: 'metric' });
        },
    },
    computed: {
        Cache() {
            return this.$store.state.cache;
        },
        UserInfo() {
            return this.$store.state.userInfo;
        },
        isLoggedIn() {
            if (Functions.isLimited()) {
                let name = this.$store.state.userInfo.name;
                return !(name === undefined || name === null || name === '');
            } else {
                return true;
            }
        },
        isLimited() {
            return Functions.isLimited();
        },
        BUS() {
            return EventBus;
        },
        AJAX() {
            return AJAX;
        },
        Moment() {
            return moment;
        },
        APIPath() {
            return window.API_SERVER;
        },
        isRunning() {
            let cache = this.Cache;
            return (cache.HostIO.Running && cache.Gateway.Running && cache.MinIO.Running);
        },
        Toast() {
            return this._toast();
        },
        PleaseWait() {
            return this._pleaseWait();
        },
        hasUpdate() {
            return false;
        },
        getBaseAPIPath() {
            let basePath;
            if (this.Cache.DevMode === true) {
                basePath = Functions.getOriginPath();
                // basePath = `//${window.location.host.replace(window.location.port, 8888)}`;
            } else {
                basePath = `//${window.location.host}`;
            }
            return basePath;
        }
    },
}
