<template>
    <div class="modal modalContainer" :style="`--bs-modal-width: ${width}px`" :id="modalId" :ref="modalId" tabindex="-1" data-bs-theme="dark">
        <div class="modal-dialog">
            <div class="modal-content bg-dark">
                <div class="modal-header bg-dark" v-if="!noTitle">
                    <h5 class="modal-title" v-html="title"></h5>
                    <button type="button" class="btn btn-sm btn-dark" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon icon="close" /></button>
                </div>
                <div class="modal-body bg-dark">
                    <slot name="content">

                    </slot>
                </div>
                <div class="modal-footer" v-if="!noFooter">
                    <slot name="footer">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "RelayerModal",
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: 'XNS Relayer'
        },
        noTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        noFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        clickClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: Number,
            required: false,
            default: 500,
        }
    },
    watch: {
        'show': function(isShown) {
            if (isShown) {
                this.entity.show();
            } else {
                this.entity.hide();
            }
        },
    },
    created() {
        this.modalId = this.generateString();
    },
    mounted() {
        this.$nextTick(async () => {
            this.entity = new Modal(this.$refs[this.modalId], {keyboard: this.clickClose, backdrop: 'static'});
            let myModalEl = document.getElementById(this.modalId);
            myModalEl.addEventListener('hidden.bs.modal', () => {
                this.BUS.emit('modal-closed');
                // this.
            })
        });
    },
    computed: {

    },
    methods: {
        generateString(length = 8) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        modalId: '',
        entity: null,
    })
}
</script>

<style scoped>

</style>
