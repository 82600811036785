import {createApp} from 'vue';
import PortalVue from 'portal-vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import axios from "axios";

import App from './App.vue';
import store from '@/system/store';
import mixin from "@/xns/mixin";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
// import './layout/stylesheet/site.scss';
import './theme/scp-theme/core.scss';

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
library.add(fas, far);

import Functions from "@/libs/Functions";

import router from '@/system/router';

const app = createApp(App, {uiState: Functions.getState()});

app.component("font-awesome-icon", FontAwesomeIcon);

if (Functions.isLimited() === false) {
    app.use(VueKeyCloak, {
        config: {
            url: 'https://auth.xns.tech/auth',
            realm: 'scprime',
            clientId: 'relayer'
        },
        init: {
            onLoad: 'login-required',
            // onLoad: 'check-sso',
            checkLoginIframe: false,
        },
        onReady: (async keycloak => {
            let userInfo = await keycloak.loadUserInfo();
            let userObject = {
                token: keycloak.token,
                ...userInfo
            };
            // console.log("UserObject", userObject);
            // console.log("Cookies", document.cookie);

            const tokenIntercept = (keycloak, userInfo) => {
                axios.interceptors.request.use(config => {
                    if (keycloak.authenticated) {
                        config.headers.KeycloakToken = keycloak.token;
                        config.headers.Authorization = `Bearer ${keycloak.token}`;
                        if (userInfo.id) {
                            config.headers.KeycloakID = userInfo.id;
                        }
                    }
                    return config;
                }, error => {
                    return Promise.reject(error)
                });
            }
            tokenIntercept(keycloak, userInfo);

            // this.tokenInterceptor();
            app.mount('#app');
            store.commit('setUserInfo', userObject);
        }),
        onInitError: (error => {
            console.log("Keycloak error...", error);
        }),
    });
}

// app.directive('tooltip', tooltip);
app.use(PortalVue);
app.use(store);
app.use(router);
app.use(FontAwesomeIcon);
app.mixin(mixin);
if (Functions.isLimited()) {
    app.mount('#app');
}

app.config.devtools = true;
app.config.performance = true;

// app.config.globalProperties.emitter = emitter;

