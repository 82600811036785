<template>
    <ul class="nav nav-tabs position-relative" :id="id" role="tablist">
        <li class="nav-item" role="presentation" v-for="tab of tabs" :key="tab.id">
            <button
                :class="`nav-link${isSelected(tab.id) ? ' active' : ''}`"
                :id="getLabel(tab.name, true)"
                data-bs-toggle="tab"
                :data-bs-target="`#${getLabel(tab.name)}`"
                type="button"
                role="tab"
                :aria-controls="slug(tab.name)"
                :aria-selected="isSelected(tab.id) ? 'true' : 'false'"
            >
                {{tab.name}}
            </button>
        </li>
        <li v-if="save" class="nav-item position-absolute end-0" role="presentation">
            <button
                class="nav-action btn btn-primary mt-1"
                type="button"
                @click="$emit('saveChanges')"
            >
                Save
            </button>
        </li>
    </ul>
    <div class="tab-content p-2" :id="`${id}Content`">
        <div
            :class="`tab-pane position-relative fade${isSelected(tab.id) ? ' show active' : ''}`"
            :id="getLabel(tab.name)"
            role="tabpanel"
            :aria-labelledby="getLabel(tab.name, true)"
            v-for="tab of tabs" :key="tab.id"
        >
            <slot :name="tab.id" :data="tab.data">
                ... {{tab.id}} Empty Slot ...
            </slot>
        </div>
    </div>
</template>

<script>
import slug from "slug";

export default {
    name: "BootstrapTabs",
    props: {
        id: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
        save: {
            type: Boolean,
            required: false,
            default: false,
        },
        initial: {
            type: String,
            required: false,
            default: '',
        }
    },
    mounted() {
        if (this.initial === '') {
            this.selected = this.tabs[0].id;
        } else {
            this.selected = this.initial;
        }
    },
    computed: {
    },
    methods: {
        isSelected(id) {
            return id === this.selected;
        },
        getLabel(name, tab) {
            if (tab === true) {
                return slug(`${name.toLowerCase()}-tab`);
            } else {
                return slug(name.toLowerCase());
            }
        },
        slug(name) {
            return slug(name);
        }
    },
    data: () => ({
        selected: 0,
    }),
}
</script>
