<template>
    <div class="card" :style="setHeight">
        <div class="card-header text-center" v-html="name"></div>
        <div class="card-body">
            <p class="card-subtitle" v-if="subtitle">{{subtitle}}</p>
            <slot></slot>
        </div>
        <div class="card-footer text-body-secondary" v-if="showFooter">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "BootstrapCard",
    props: {
        name: {
            type: String,
            required: true,
        },
        fixedHeight: {
            type: String,
            required: false,
            default: null
        },
        showFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        }
    },
    computed: {
        setHeight() {
            if (this.fixedHeight === null) {
                return false;
            } else {
                return {'height': this.fixedHeight, 'min-height': this.fixedHeight, 'max-height': this.fixedHeight, 'margin': 'auto'};
            }
        }
    }
}
</script>
