<template>
    <div class="mb-1">
        <div :class="`input-group ${small ? 'input-group-sm': ''}`">
            <span v-if="prefix" class="input-group-text" id="basic-addon1">{{prefix}}</span>
            <div class="form-floating">
                <select
                    class="form-select"
                    :id="inputId"
                    :disabled="disabled"
                    :aria-describedby="`${inputId}Help`"
                    :aria-label="label"
                    v-model="baseValue"
                    @change="$emit('valueChange', label, baseValue)"
                >
                    <option class="bg-dark" :selected="isSelected(option)" v-for="(option, id) of values" :key="id" :value="option.key || option">{{option.value || option}}</option>
                </select>
                <label v-if="!hideTitle" :for="inputId" class="form-label">{{title ? title : label}}</label>
            </div>
            <span v-if="suffix" class="input-group-text" id="basic-addon2">{{suffix}}</span>
        </div>
        <div v-if="help !== ''" :id="`${inputId}Help`" class="form-text">{{help}}</div>
    </div>
</template>

<script>
export default {
    name: "BootstrapSelect",
    props: {
        label: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        hideTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        help: {
            type: String,
            required: false,
            default: '',
        },
        defaultValue: {
            type: [String, Number],
            required: false,
            default: '',
        },
        values: {
            type: Array,
            required: true,
        },
        prefix: {
            type: String,
            required: false,
            default: ''
        },
        suffix: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        let value = this.$attrs.modelValue;
        this.inputId = this.generateString(8);

        if (value === undefined) {
            this.baseValue = this.defaultValue;
        } else {
            if (value !== '') {
                this.baseValue = value;
            } else if (value === '' && this.defaultValue !== '') {
                this.baseValue = this.defaultValue;
            }
        }
    },
    watch: {
        defaultValue: function(newValue) {
            this.baseValue = newValue;
        }
    },
    computed: {

    },
    methods: {
        isSelected(id) {
            return (id.key || id) === this.baseValue;
        },
        generateString(length) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        inputId: '',
        baseValue: '',
    })
}
</script>
