import { createStore } from 'vuex';
import Functions from "@/libs/Functions";

export default createStore({
    state () {
        return {
            count: 0,
            cache: null,
            userInfo: {},
        }
    },
    getters: {
        isLoggedIn(state) {
            if (Functions.isLimited()) {
                if (window.localStorage.getItem('mt_login') === null) {
                    state.userInfo.name = null;
                } else {
                    let userConfig = JSON.parse(window.localStorage.getItem('mt_login'));
                    state.userInfo.name = userConfig.name;
                    state.userInfo.public = userConfig.public;
                    state.userInfo.iv = userConfig.iv;
                    state.userInfo.data = userConfig.encryptedData
                    if (state.userInfo.public) {
                        state.userInfo.expires = userConfig.expires;
                        setTimeout(() => {
                            if (new Date().getTime() > state.userInfo.expires) {
                                window.localStorage.removeItem('mt_login');
                                state.userInfo = {};
                                window.location.reload();
                            }
                        }, 10000);
                    }
                }
                let name = state.userInfo.name;
                return name !== null;
            } else {
                return true;
            }
        },
    },
    mutations: {
        setCache(state, data) {
            state.cache = data;
        },
        setUserInfo(state, data) {
            state.userInfo = data;
        },
        increment (state) {
            state.count++
        }
    }
})
